import {
  checkAuthRequest,
  loginRequest,
  changePasswordRequest,
} from "../../http/userRequests";
import { SET_BOT_RUNNING, SET_USER } from "../types/userTypes";

const STORAGE_TOKEN_KEY = "token";

export const userLogin = (payload) => (dispatch) => {
  return loginRequest(payload)
    .then((response) => {
      localStorage.setItem(STORAGE_TOKEN_KEY, response.data.token);
      dispatch({
        type: SET_USER,
        payload: { user: response.data, isLoggedIn: true },
      });
    })
    .catch((error) => {
      console.log(error.data);
    });
};

export const userLogout = () => (dispatch) => {
  localStorage.removeItem(STORAGE_TOKEN_KEY);
  dispatch({
    type: SET_USER,
    payload: { user: {}, isLoggedIn: false },
  });
};

export const checkAuth = () => (dispatch) => {
  return checkAuthRequest()
    .then((response) => {
      dispatch({
        type: SET_USER,
        payload: { user: response.data.user, isLoggedIn: true },
      });
    })
    .catch((error) => {
      console.log(error.data);
    });
};

export const setBotRunning = (payload) => (dispatch) => {
  dispatch({
    type: SET_BOT_RUNNING,
    payload: payload,
  });
};

export const changePassword = (payload) => async () => {
  const response = await changePasswordRequest(payload);
  const newToken = response.data.token;
  if (newToken) localStorage.setItem(STORAGE_TOKEN_KEY, newToken);
};
