import { useDispatch } from "react-redux";
import { userLogout } from "../../../redux/actions/userActions";
import { Formik } from "formik";
import { changePassword } from "../../../redux/actions/userActions";

import './Profile.css';

const PASSWORD_ERROR_TEXT = `Passwords don't match`;

const handleValidateForm = values => {
    const errors = {};

    for (const element in values) {
        if (!values[element] || !(values[element].trim())) errors[element] = 'Required';
    }

    const passwordConfirmation = values.passwordConfirmation;

    if (passwordConfirmation && (values.newPassword !== passwordConfirmation)) {
        errors.newPassword = PASSWORD_ERROR_TEXT;
        errors.passwordConfirmation = PASSWORD_ERROR_TEXT;
    }
    
    return errors;
};

const Profile = () => {
    const dispatch = useDispatch();
    
    const onLogout = (e) => {
        e.preventDefault()
        dispatch(userLogout());
    }

    const onSubmit = async (values, { setSubmitting }) => {
        await dispatch(changePassword(values));
        setSubmitting(false);
    }

  return (
    <>
        <h2>Profile</h2>

        <div className="contentWrapper">
            <Formik
                onSubmit={onSubmit}
                validate={handleValidateForm}
                initialValues={{
                    oldPassword: '',
                    newPassword: '',
                    passwordConfirmation: '',
                    changePasswordSecret: '',
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                }) => (
                    <form onSubmit={handleSubmit} className="profileForm">
                        <input
                            type="password"
                            name="oldPassword"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.oldPassword}
                            placeholder='Old password'
                        />
                        {errors.oldPassword && touched.oldPassword && (<div className='errorText'>{errors.oldPassword}</div>)}
                        
                        <input
                            type="password"
                            name="newPassword"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.newPassword}
                            placeholder='New password'
                        />
                        {errors.newPassword && touched.newPassword && (<div className='errorText'>{errors.newPassword}</div>)}
                        
                        <input
                            type="password"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            name="passwordConfirmation"
                            placeholder='Password confirmation'
                            value={values.passwordConfirmation}
                        />
                        {errors.passwordConfirmation && touched.passwordConfirmation && (
                            <div className='errorText'>{errors.passwordConfirmation}</div>)
                        }
                        
                        <input
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder='Secret word'
                            name="changePasswordSecret"
                            value={values.changePasswordSecret}
                        />
                        {errors.changePasswordSecret && touched.changePasswordSecret && (
                            <div className='errorText'>{errors.changePasswordSecret}</div>
                        )}

                        <button type="submit" disabled={isSubmitting}>Submit</button>
                    </form>
                )}
            </Formik>

            <button onClick={onLogout}>Logout</button>
        </div>
    </>
  )
}

export default Profile